import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "25",
  height: "25",
  viewBox: "0 0 25 25",
  fill: "#212529"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "inherit",
      d: "M12.5 16.0372L6.5 10.0372L7.9 8.63721L12.5 13.2372L17.1 8.63721L18.5 10.0372L12.5 16.0372Z"
    }, null, -1)
  ])))
}
export default { render: render }